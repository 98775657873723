<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template>
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Devices'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <div class="d-flex flex-column">

          <!-- General -->
          <div class="mb-3">
            <h3 class="font-weight-bold">General</h3>

            <!-- String #ID, Software -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-2"
                label="String #ID"
                placeholder="String #ID"
                :lazy="false"
                :value="form.sid"
                disabled
              />

              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Software:</label>
                <CSwitch
                  :checked="form.software"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  size="lg"
                  :lazy="false"
                  disabled
                />
              </div>
            </div>

            <!-- WS Connection -->
            <fieldset v-if="form.ws_status != null" class="border rounded p-3 bg-light mb-5">
              <legend class="w-auto bg-white rounded py-1 px-2 live-connection-title">
                Live Connection
              </legend>
              <div class="d-flex flex-lg-row flex-column flex-gap-2">
                <CInput
                    v-if="form.ws_status != 1"
                    class="w-100 mr-2 ws-status-text offline"
                    label="Connection Status"
                    placeholder="Connection Status"
                    :lazy="false"
                    value="OFFLINE"
                    disabled
                  />
                  <CInput
                    v-else
                    class="w-100 mr-2 ws-status-text online"
                    label="Connection Status"
                    placeholder="Connection Status"
                    :lazy="false"
                    value="ONLINE"
                    disabled
                  />
                <CInput
                  v-if="form.ws_lastcheck_on"
                  class="w-100 mr-2"
                  label="Last Connection"
                  placeholder="Last Connection"
                  :lazy="false"
                  :value="form.ws_lastcheck_on"
                  disabled
                />
                <CInput
                  v-if="form.ws_lastcheck_off"
                  class="w-100"
                  label="Last Disconnection"
                  placeholder="Last Disconnection"
                  :lazy="false"
                  :value="form.ws_lastcheck_off"
                  disabled
                />
              </div>
            </fieldset>
          </div>

          <!-- Device Information -->
          <div class="mb-3">
            <h3 class="mb-3 font-weight-bold">Device Information</h3>
            <!-- IMEI Number, Model -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-2"
                label="IMEI"
                placeholder="IMEI"
                :lazy="false"
                :value="form.imei"
                disabled
              />

              <CInput
                class="w-100"
                label="Model"
                placeholder="Model"
                :lazy="false"
                :value="form.model" 
                disabled
              />
            </div>

            <!-- Serial Number, SIM Card Number -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                class="w-100 mr-2"
                label="Serial Number"
                placeholder="Serial Number"
                :lazy="false"
                :value="form.serienr"
                disabled
              />

              <CInput
                class="w-100"
                label="SIM Card Number"
                placeholder="SIM Card Number"
                :lazy="false"
                :value="form.simkaartnr"
                disabled
              />
            </div>
            <!-- Versioning -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                v-if="form.version"
                class="w-100 mr-2"
                label="App Version"
                placeholder="App Version"
                :lazy="false"
                :value="form.version"
                disabled
              />
              <CInput
                v-if="form.os"
                class="w-100 mr-2"
                label="OS"
                placeholder="OS"
                :lazy="false"
                :value="form.os"
                disabled
              />
              <CInput
                v-if="form.os_device"
                class="w-100"
                label="Device OS (Only for MB)"
                placeholder="Device OS (Only for MB)"
                :lazy="false"
                :value="form.os_device"
                disabled
              />
            </div>

            <!-- Connectivity -->
            <div class="mb-3 d-flex flex-column flex-md-row">
              <CInput
                v-if="form.connectivity"
                class="w-100"
                label="Connectivity"
                placeholder="Connectivity"
                :lazy="false"
                :value="form.connectivity"
                disabled
              />
            </div>
          </div>

          <!-- Notes -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <CTextarea
              class="w-100"
              rows="2"
              label="Notes"
              placeholder="Notes"
              :lazy="false"
              :value.sync="$v.form.notities.$model"
            />
          </div>

          <!-- Linked Restaurant -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <div class="form-group w-100 mr-2">
              <label class="d-block">Restaurant:</label>
              <v-select
                :reduce="c => c.value"
                placeholder="Please select.."
                :searchable="true"
                v-model="$v.form.restaurant.$model"
                :options="restaurants"
              />
            </div>

            <div v-if="form.restaurant" role="group" class="w-100 form-group">
              <label class="d-block">&nbsp;</label>
              <CButton
                color="danger"
                size="sm"
                shape="pill"
                @click="() => form.restaurant = null"
              >
                <CIcon name="cil-x" class="mb-1"/>&nbsp;Unlink
              </CButton>
            </div>
          </div>
        </div>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton
        color="primary"
        :disabled="submitted"
        @click="submit()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted"/>

  </CCard>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: 'DeviceItem',

  data() {
    return {
      form: {},
      submitted: false,

      itemName: '',
      restaurants: [],

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      notities: { },
      restaurant: { }
    }
  },

  created () {
    if(this.itemId) {
      this.form = this.getForm(this.itemId)
    }
    else
      this.form = this.getForm()
  },

  mounted() {
    this.getRestaurantNames();
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    async submit () {
      if (!this.isValid) {
        this.validate();
        return;
      }

      await this.update()
    },

    async update() {
      this.submitted = true;
      await this.$axios.put(this.$backend.DEVICE.UPDATE.replace("{id}", this.itemId), {'sid': this.form.sid, 'restaurant': this.form.restaurant, 'software': this.form.software, 'notities': this.form.notities})
        .then((response)=>{
          this.itemName = response.data.sid
          this.successAlertMessage = {itemName: this.itemName, message: 'device updated.'}
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
        });
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = {
        sid: null,
        serienr: null,
        simkaartnr: null,
        notities: null,
        software: this.$store.state.deviceSoftware,
        version: null,
        os: null,
        os_device: null,
        imei: null,
        model: null,
        restaurant: null,
        connectivity: null,
        ws_lastcheck_off: null,
        ws_lastcheck_on: null,
        ws_status: null
      }

      if(id) {
        this.$axios
          .get(
            this.$backend.DEVICE.SHOW.replace("{id}", id)
          )
          .then((res) => {
            form.sid =  res.data.sid;
            form.serienr =  res.data.serienr;
            form.simkaartnr =  res.data.simkaartnr;
            form.sid =  res.data.sid;
            form.notities =  res.data.notities;
            form.software =  res.data.software;
            form.version =  res.data.version;
            form.os =  res.data.os;
            form.os_device =  res.data.os_device;
            form.imei =  res.data.imei;
            form.model =  res.data.model;
            form.connectivity = res.data.connectivity;
            form.ws_lastcheck_off = res.data.ws_lastcheck_off;
            form.ws_lastcheck_on = res.data.ws_lastcheck_on;
            form.ws_status = res.data.ws_status;

            form.restaurant =  res.data.restaurants.length > 0 ? res.data.restaurants[0].id : null;

            this.itemName = res.data.sid;
          })
          .catch((error) => {
            this.$router.push({ name: 'Devices' })
          })
      }
      return form;
    },

    getRestaurantNames() {
      this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.restaurants = response.data.sort((a,b) => b.value - a.value);
        })
        .catch(() => { this.restaurants = [] })
    },
  }
}
</script>

<style>
input {
  background-color: white !important;
}

.live-connection-title {
  font-weight: bold;
  font-size: 1.25rem;
}

.ws-status-text input {
  font-weight: bold;
}

.online input {
  color: green;
}

.offline input {
  color: red;
}
</style>